import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { Card } from "antd";
import { notification } from "antd";

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { auth } from "../actions/auth";
import { useHistory } from "react-router-dom";

const { Meta } = Card;

const Login = ({isAuth,authenticate, notificationData }) => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    authenticate(values);
  };

  useEffect(() => {
    if (notificationData.show) openNotificationWithIcon(notificationData);
  }, notificationData.showNotification);

  const openNotificationWithIcon = (data) => {
    const close = () => {
      console.log(
        "Notification was closed. Either the close button was clicked or duration time elapsed."
      );
    };

    notification[data.type]({
      message: data?.title,
      description: data.body,
      placement : 'leftBottom',
      style: {
        marginTop: 60,
      },
      onclose: close,
    });
  };


  const history = useHistory();

  useEffect(() => {

    if(isAuth){

      history.push("/admin/dashboard");

    }
    
  }, [isAuth]);


  return (
    <div
      style={{
        margin: "auto",
        display: "block",
        width: "20%",
        marginTop: "10%",
      }}
    >
      <Card
        hoverable
        style={{ width: 400, border: "1px solid #000000" }}
        cover={
          <img
            alt="example"
            src="https://img.freepik.com/free-vector/random-square-halftone-pattern_1409-1062.jpg?size=626&ext=jpg"
          />
        }
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  notificationData: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (data) => dispatch(auth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// import DataProduct from "../component/lists/Products";
// import FilterModelProducts from "../component/filters/Products";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import { PageHeader, Descriptions } from "antd";

import { Statistic, Card } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  IdcardOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";

import { fetchDashboardData  } from "../actions/common";


const { Option } = Select;

function PageDashboardAdmin({ data, getData, REFRESH, addData, updateData, dashboard_data }) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const history = useHistory();

  const updateDrawer = () => {
    toggleDrawer(!showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    getData({ query: "" });
    form.resetFields();
    toggleDrawer(false);
  }, [REFRESH]);

  const onUpdateItem = (data) => {
    console.log(data);
    updateData({ ...data, status: !data.status });
  };

  const onFormSubmit = (values) => {
    console.log("form data", values);

    addData({ ...values, status: 1 });
  };


  return (
    <div className="site-page-header-ghost-wrapper">
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total Users"
              value={dashboard_data?.total_active_user}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<DollarCircleOutlined />}
              prefix=""
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Active Invites"
              value={dashboard_data?.total_product}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>

        <Col span={12}>
          <Card>
            <Statistic
              title="Total Orders"
              value={dashboard_data?.total_sale}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total Orders ( Today )"
              value={dashboard_data?.total_sale_today}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
      </Row>


      <Drawer
        title="Create a new item"
        width={420}
        onClose={updateDrawer}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 120 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={updateDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          onFinish={onFormSubmit}
          layout="vertical"
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Provider Name"
                rules={[
                  { required: true, message: "Please enter provider name" },
                ]}
              >
                <Input placeholder="eg. South Carolina Lottery" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}




const mapStateToProps = (state) => ({
  dashboard_data: state.common.dashboard_data

});

const mapDispatchToProps = (dispatch) => ({

  getData: (data) => dispatch(fetchDashboardData())

});

export default connect(mapStateToProps, mapDispatchToProps)(PageDashboardAdmin);

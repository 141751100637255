import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import { notification } from "antd";

import { Layout, Menu, Breadcrumb, Button, Popover, List } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  MenuOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  IdcardOutlined,
  StockOutlined,
  CrownTwoTone,
} from "@ant-design/icons";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";


import { Footer } from "antd/lib/layout/layout";
import PageUsers from "./PageUsers";
import PageDashboardAdmin from "./PageDashboardAdmin";
import User from "./fragments/User";
import PageProduct from "./PageProduct";
import PageCategory from "./PageCategory";
import PageBanner from "./PageBanner";
import PageCoupon from "./PageCoupon";
import PageNotification from "./PageNotification";
import PageConfiguration from "./PageConfiguration";
import PageOrder from "./PageOrder";
import { useHistory } from "react-router-dom";

import { fetchLookup } from "../actions/common";
import PageTags from "./PageTags";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

function CorePage({
  notificationData,
  logoutUser,
  getLookup,
  logout,
  roleType,
}) {
  const { location } = useHistory();

  console.log(location);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getLookup();
  }, []);

  useEffect(() => {
    if (notificationData.show) openNotificationWithIcon(notificationData);
  }, notificationData.showNotification);

  const openNotificationWithIcon = (data) => {
    const close = () => {
      console.log(
        "Notification was closed. Either the close button was clicked or duration time elapsed."
      );
    };

    notification[data.type]({
      message: data.title,
      description: data.body,
      placement: "bottomLeft",

      style: {
        marginTop: 60,
      },

      onclose: close,
    });
  };

  const handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };

  const toggleCollapsed = () => {
    // this.setState({
    //   collapsed: !this.state.collapsed,
    // });
  };

  const handleError = (data) => {
    console.log("scan error enter", data);
  };

  const handleScan = (data) => {
    console.log("scan enter", data);
  };

  const handleEnter = (data) => {
    // this.setState({
    //   collapsed: !this.state.collapsed,
    // });

    console.log("key enter", data);
  };

  return (
    <Layout
      style={{
        height: "100%",
      }}
    >
      <Header
        className="header"
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={toggleDrawer}>
          {React.createElement(false ? MenuOutlined : MenuOutlined)}
        </Button>
        <Popover
          placement="topRight"
          title={"Settings"}
          content={
            <List>
              <List.Item>
                <Link to="/admin/users">
                  <List.Item.Meta
                    avatar={<IdcardOutlined />}
                    title={"Profile"}
                  />
                </Link>
              </List.Item>

              <List.Item
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </List.Item>
            </List>
          }
          trigger="click"
        >
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key={1} icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
          </Menu>
        </Popover>
      </Header>
      <Layout>
        <Sider
          width={256}
          collapsed={!open}
          collapsedWidth={0}
          style={{
            marginTop: "60px",
            // background: "rgb(250, 250, 250)"
          }}
        >
          <Layout
            style={{
              height: "100%",
              display: "flex",
              // background: "rgb(250, 250, 250)"
            }}
          >
            <Content
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // background: "rgb(250, 250, 250)"
              }}
            >
              <Menu
                theme={"light"}
                onClick={() => {}}
                // defaultOpenKeys={['sub1']}
                // defaultSelectedKeys={["1"]}
                // defaultOpenKeys={["sub1"]}
                defaultSelectedKeys={[location.pathname]}
                defaultOpenKeys={[
                  "sub1","sub4"
                  // {
                  //   [location.pathname === "/admin/lottery"]: "sub1",
                  //   [location.pathname === "/admin/organiser"]: "sub1",
                  //   [location.pathname === "/admin/invoices"]: "sub4",

                  //   [location.pathname === "/admin/users"]: "sub4",
                  //   [location.pathname === "/admin/stores"]: "sub4",
                  //   [location.pathname === "/admin/customers"]: "sub4",
                  //   [location.pathname === "/admin/products"]: "sub4",
                  //   [location.pathname === "/admin/category"]: "sub4",
                  //   [location.pathname === "/admin/banner"]: "sub4",

                  // }.true,
                ]}
                mode="inline"
              >
                <Menu.Item key="/admin/dashboard" icon={<DashboardOutlined />}>
                  <Link to="/admin/dashboard">Dashboard</Link>
                </Menu.Item>
                {roleType == 1 && (
                  <SubMenu
                    key="sub1"
                    icon={<IdcardOutlined />}
                    title="Lotteries"
                  >
                    <Menu.Item key="/admin/lottery">
                      <Link to="/admin/lottery">Lottery</Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/organiser">
                      <Link to="/admin/organiser">Organiser</Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                {roleType == 2 && (
                  <SubMenu
                    key="sub2"
                    icon={<StockOutlined />}
                    title="Inventories"
                  >
                    <Menu.Item key="/admin/inventory">
                      <Link to="/admin/inventory">Inventory</Link>
                    </Menu.Item>

                    <Menu.Item key="/admin/billing">
                      <Link to="/admin/billing">Billing</Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/invoices">
                      <Link to="/admin/invoices">Invoices</Link>
                    </Menu.Item>

                  </SubMenu>
                )}
                <SubMenu key="sub4" icon={<UserOutlined />} title="Manage">
                  { (
                    <Menu.Item key="/admin/users">
                      <Link to="/admin/users">Users</Link>
                    </Menu.Item>
                  )}
                   {
                    <Menu.Item key="/admin/tags">
                      <Link to="/admin/tags">Tags</Link>
                    </Menu.Item>
                  }
   {
                    <Menu.Item key="/admin/category">
                      <Link to="/admin/category">Category</Link>
                    </Menu.Item>
                  }

{
                    <Menu.Item key="/admin/banner">
                      <Link to="/admin/banner">Banner</Link>
                    </Menu.Item>
                  }
{
                    <Menu.Item key="/admin/coupon">
                      <Link to="/admin/coupon">Coupon</Link>
                    </Menu.Item>
                  }
                  {
                    <Menu.Item key="/admin/notification">
                      <Link to="/admin/notification">Notification</Link>
                    </Menu.Item>
                  }
                                    {
                    <Menu.Item key="/admin/configuration">
                      <Link to="/admin/configuration">Configuration</Link>
                    </Menu.Item>
                  }
{
                    <Menu.Item key="/admin/products">
                      <Link to="/admin/products">Products</Link>
                    </Menu.Item>
                  }
                  
                </SubMenu>

                <SubMenu key="sub5" icon={<UserOutlined />} title="Order Management">
              
{
                    <Menu.Item key="/admin/order">
                      <Link to="/admin/order">Orders</Link>
                    </Menu.Item>
                  }
                  
                </SubMenu>

              </Menu>
              <Menu mode="inline">
                {" "}
                <Menu.Item key="/admin/dashboard" icon={<CrownTwoTone />}>
                  <Link to="/admin/dashboard">Support</Link>
                </Menu.Item>
              </Menu>
            </Content>
            <Footer></Footer>
          </Layout>
        </Sider>
        <Layout
          style={{
            padding: "0 24px 24px",
            overflow: "auto",
            marginTop: "80px",
          }}
        >
          {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Switch>
           

              <Route exact path="/admin/users">
                {<PageUsers /> }
              </Route>
           

           
              <Route exact path="/admin/products">
                {roleType == 1 ? <PageProduct /> : <PageProduct />}
              </Route>

              <Route exact path="/admin/tags">
                <PageTags />
              </Route>
           
              <Route exact path="/admin/category">
                <PageCategory />
              </Route>


              <Route exact path="/admin/banner">
                <PageBanner />
              </Route>

              <Route exact path="/admin/coupon">
                <PageCoupon />
              </Route>

              <Route exact path="/admin/notification">
                <PageNotification />
              </Route>


              <Route exact path="/admin/configuration">
                <PageConfiguration />
              </Route>

              <Route exact path="/admin/order">
                <PageOrder />
              </Route>

              <Route exact path="/admin/dashboard">
                {roleType == 1 ? (
                  <PageDashboardAdmin />
                ) : (
                  <PageDashboardAdmin />
                )}
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  notificationData: state.notification,
  roleType: state.auth.roleType,
});

const mapDispatchToProps = (dispatch) => ({
  getLookup: () => dispatch(fetchLookup()),
  logout: () =>
    dispatch({
      type: "AUTH_LOGOUT",
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CorePage);

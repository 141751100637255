import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// import DataProduct from "../component/lists/Products";
// import FilterModelProducts from "../component/filters/Products";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import { fetchData, createData, putData } from "../actions/users";
import CustomPaginatedTable from "../component/CustomPaginatedTable";

const { Option } = Select;

function PageUsers({ data, getData, addData, updateData, REFRESH }) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({
    role_type: 1,
    isFormEdit: false,
  });

  const [filterData, setFilterData] = useState({ query: "" });
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");

  const history = useHistory();

  const updateDrawer = () => {
    toggleDrawer(!showDrawer);
  };

  const [form] = Form.useForm();

  const onFormSubmit = (values) => {
    console.log("form data", values);

    if (values.isFormEdit) updateData({ ...values, user_id: values.uniId });
    else addData({ ...values, status: 1 });
  };

  const onUpdateItem = (data, updates) => {
    updateData({ ...data, ...updates });
  };

  const onActionItem = (data) => {
    updateFormValues({ ...data, isFormEdit: true, uniId: data.user_id });
    form.setFieldsValue({ ...data, isFormEdit: true, uniId: data.user_id });
    updateDrawer();

    // updateProduct(data.product_id, {...data, is_private: !data.is_private});
  };

  const validateForm = (_, values) => {
    console.log("validate", values);

    if (formValues.isFormEdit) {
      return Promise.resolve();
    } else if (!values || values === "" || values.length < 6) {
      return Promise.reject(
        new Error("Password is mandatory and need to be greater than 6 digits")
      );
    } else {
      return Promise.resolve();
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (item, record, index) => (
        <>
          {record.first_name} {record.middle_name} {record.last_name}
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Type",
      key: "roles",
      dataIndex: "roles",
      render: (roles) => (
        <>
          <Tag color={roles?.length > 0 ? "green" : "geekblue"}>
            {roles?.length > 0 ? "ADMIN" : "USER"}
          </Tag>
        </>
      ),
    },

    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
    },

    // {
    //   title: "Email Verify",
    //   key: "email_verified",
    //   render: (item, record, index) => (
    //     <Space size="middle">
    //       <Switch
    //         checked={item.email_verified}
    //         onChange={() => onUpdateItem(item, { email_verified: !item.email_verified })}
    //       />
    //     </Space>
    //   ),
    // },
    {
      title: "Status",
      key: "status",
      render: (item, record, index) => (
        <Space size="middle">
          <Switch
            checked={item.status}
            onChange={() => onUpdateItem(item, { status: !item.status })}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 22 }}
            onClick={() => onActionItem(item, record, index)}
          />

          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => onUpdateItem(record, { is_deleted: true })}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 22, color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  console.log(data);
  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Users"
        className="site-page-header"
        extra={[
          <Input.Search
            allowClear
            style={{ width: "400px" }}
            defaultValue=""
            placeholder="Search anything.."
            onChange={(e) => {
              console.log(e.target.value);
              setPage(0);
              setQuery(e.target.value.toLowerCase());
            }}
          />,

          // <Button
          //   key="1"
          //   type="primary"
          //   onClick={() => {
          //     form.resetFields();
          //     updateFormValues({});
          //     updateDrawer();
          //   }}
          // >
          //   Add
          // </Button>,
        ]}
      ></PageHeader>
      <CustomPaginatedTable
        columns={columns}
        getData={getData}
        query={query}
        setPage={setPage}
        page={page}
        REFRESH={REFRESH}
        dataSource={data}
        form={form}
      />
      <Drawer
        title="Create a new item"
        width={420}
        onClose={updateDrawer}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={updateDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          values={formValues}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="isFormEdit" hidden={true}></Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="uniId" hidden={true}></Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="role_type" label="User Type">
                <Select defaultValue={2} placeholder="Please choose user typr">
                  <Option value={1}>Admin</Option>
                  <Option value={2}>User</Option>
                </Select>
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="middle_name"
                label="Middle Name"
                rules={[{ required: false, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  { required: false, message: "Please enter user mobile" },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter user email" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            {
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ validator: validateForm }]}
                >
                  <Input placeholder="Please enter user password" />
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.users.list,
  REFRESH: state.levent.USERS_REFRESH,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (data) => dispatch(fetchData(data)),
  addData: (data) => dispatch(createData(data)),
  updateData: (data) => dispatch(putData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageUsers);

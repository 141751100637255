import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { fmtDate } from "../utils/datetime";

import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, Switch, InputNumber } from "antd";

import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";

import {
  createData as createCMS,
  fetchData as fetchCMS,
  putData as putCMS,
} from "../actions/cms";
import { API_BASE_URL } from "../config";
import CustomPaginatedTable from "../component/CustomPaginatedTable";

const CMS_TYPE = "order";

function PageOrder({
  data,
  getData,
  addCMS,
  updateCMS,
  REFRESH,
  lookup,
  addUnit,
  unitData,
  bypassData,
  updateProduct,
  getCMS,
}) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({
    role_type: 1,
    isFormEdit: false,
  });
  const [filterData, setFilterData] = useState({ query: "" });
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const history = useHistory();

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  const onFormSubmit = (values) => {
    console.log("form data", values);

    let data = {
      ...values,
      image_url: values?.image_url?.url
        ? values.image_url.url
        : values.image_url,
    };

    // console.log("toggle filter dialog called", data);

    if (data.isFormEdit) {
      updateCMS({ ...data, coupon_id: data.uniId }, data.uniId, CMS_TYPE);
    } else {
      addCMS(data, CMS_TYPE);
    }
  };

  const onUpdateItem = (data, updates) => {
    updateCMS({ ...data, ...updates }, data.coupon_id, CMS_TYPE);
  };

  const onActionItem = (item, data, index) => {
    form.setFieldsValue({
      title: data.title,
      detail: data.detail,
      slug: data.slug,
      uniId: data.coupon_id,
      isFormEdit: true,
      image_url: data.image_url,
    });

    updateDrawer(true);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (item, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "created_at",
      key: "created_at",
      render: (item, record, index) => fmtDate(item),
    },

    {
      title: "Username",
      dataIndex: "user_info",
      key: "user_info",
      width: 200,
      render: (item, record, index) => item.first_name,
    },
    {
      title: "Detail",
      dataIndex: "items",
      key: "items",
      width: 400,
      render: (items, record, index) =>
        `${items?.[0]?.product?.["name"]} ( ${items?.[0]?.product?.category?.data?.name} )`,
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      width: 100,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 100,
    },
    {
      title: "Coupon",
      dataIndex: "coupon_info",
      key: "coupon_info",
      width: 100,
      render: (coupon_info) => (
        <>
          <Tag color={coupon_info ? "pink" : ""}>{coupon_info?.title}</Tag>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "order_status",
      render: (status) => (
        <>
          <Tag color={status === 1 ? "green" : "red"}>
            {status === 1 ? "SUCCESS" : "FAILED"}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <Space size="middle">
          {/* <EditOutlined
            style={{ fontSize: 22 }}
            onClick={() => onActionItem(item,record, index)}
          /> */}

          <Popconfirm
            title="It will open a new window?"
            onConfirm={() => {
              window.open(
                `${API_BASE_URL?.toString()?.replace(
                  "v1/",
                  "i/"
                )}${record?.payment_info?.[0]?.payment_gtw_id
                  ?.toString()
                  ?.replace("pay_", "")
                  ?.replace("order_", "")}`,
                "_blank"
              );
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <EyeOutlined style={{ fontSize: 22, color: "green" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [newItem, addNewItem] = useState("");
  const onNameChange = (event) => {
    addNewItem(event.target.value);
  };

  const addItem = (data) => {
    console.log(data);

    if (data != "") addUnit({ name: data });

    addNewItem("");
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title={`${CMS_TYPE}s`}
        className="site-page-header"
        extra={[
          <Input.Search
            allowClear
            style={{ width: "400px" }}
            defaultValue=""
            placeholder="Search anything.."
            onChange={(e) => {
              console.log(e.target.value);
              setPage(0);
              setQuery(e.target.value.toLowerCase());
            }}
          />,
        ]}
      ></PageHeader>
      <CustomPaginatedTable
        columns={columns}
        getData={getCMS}
        query={query}
        setPage={setPage}
        page={page}
        REFRESH={REFRESH}
        dataSource={data}
        form={form}
      />

      <Drawer
        title="Create a new item"
        width={600}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => updateDrawer(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          values={formValues}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="isFormEdit" hidden={true}></Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="uniId" hidden={true}></Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Code ( 200 )"
                rules={[
                  {
                    required: false,
                    message: "Please input coupon code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="detail"
                label="Detail"
                rules={[
                  {
                    required: false,
                    message: "Please input detail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="is_flat">
                <Switch
                  defaultChecked
                  checkedChildren="Flat Off"
                  unCheckedChildren="Percentage Off"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: false,
                    message: "Please input amount!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="percentage"
                label="Percentage"
                rules={[
                  {
                    required: false,
                    message: "Please input percentage!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="max_amount"
                label="Max Amount"
                rules={[
                  {
                    required: false,
                    message: "Please input max amount!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="max_uses"
                label="Max Uses"
                rules={[
                  {
                    required: false,
                    message: "Please input max Uses!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>

            {/* <Col span={24}>
              <Form.Item
                name="image_url"
                label="Image"
                // rules={[
                //   { required: true, message: "Please add image" },
                // ]}
              >
                <SingleUpload />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  data: state.cms?.[CMS_TYPE],
});

const mapDispatchToProps = (dispatch) => ({
  updateCMS: (data, uniId, type) => dispatch(putCMS(data, uniId, type)),
  addCMS: (data, type) => dispatch(createCMS(data, type)),
  getCMS: (data, type) => dispatch(fetchCMS(data, CMS_TYPE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageOrder);

import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function CustomPaginatedTable({
  columns,
  dataSource,
  REFRESH,
  getData,
  query,
  page,
  setPage,
  form,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
    const loadData = async () => {
      setLoading(true);
      await getData({ query, page, limit: 10 });
      setLoading(false);
    };
    const debounceTimer = setTimeout(loadData, 200);

    return () => clearTimeout(debounceTimer);
  }, [page, query, REFRESH]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        loading={loading}
        pagination={false}
      />
      <div style={{ textAlign: "right", marginTop: 24 }}>
        <Button
          icon={<LeftOutlined />}
          type="text"
          size="small"
          style={{ marginRight: 12 }}
          onClick={() => setPage((prev) => prev - 1)}
          disabled={page === 0}
        />
        <Button
          icon={<RightOutlined />}
          type="text"
          size="small"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={dataSource?.length < 10}
        />
      </div>
    </>
  );
}

export default CustomPaginatedTable;

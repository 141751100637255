import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Divider,
  List,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import SingleUpload from "../component/SingleUpload";
import { Cascader } from "antd";
import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import {
  createData as createCMS,
  fetchData as fetchCMS,
  putData as putCMS,
} from "../actions/cms";
import { fetchLookup } from "../actions/common";

import { getStateCountry, filterCountryState } from "../utils/mappers";

const { Option } = Select;
const { TextArea } = Input;

const CMS_TYPE = "tags";

function PageTags({
  data,
  getData,
  addCMS,
  updateCMS,
  REFRESH,
  lookup,
  addUnit,
  unitData,
  bypassData,
  updateProduct,
  getCMS,
}) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({
    role_type: 1,
    isFormEdit: false,
  });
  const [filterData, setFilterData] = useState({ query: "" });

  const history = useHistory();

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    getCMS({ });
    updateDrawer(false);
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log("form data", values);

    let data = {
      ...values,
      image_url: values?.image_url?.url
        ? values.image_url.url
        : values.image_url,
    };

    // console.log("toggle filter dialog called", data);


        if (data.isFormEdit) {
          updateCMS({ ...data, banner_id: data.uniId }, data.uniId, CMS_TYPE);
        } else {
          addCMS(data, CMS_TYPE);
        }
      
    


  };

  const onUpdateItem = (data, updates) => {


      updateCMS({ ...data, ...updates },data.tag_id,  CMS_TYPE);

    
  };

  const onActionItem = (item, data, index) => {

 

  
      form.setFieldsValue({
        title: data.title,
        detail: data.detail,
        slug: data.slug,
        uniId: data.tag_id,
        isFormEdit: true,
      });
    

    updateDrawer(true);

  };

  const columns = [

    {
      title: "#",
      key: "#",
      render: (item, record, index) => (
        index +1
      ),
    },

  
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 400,
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      width: 400,
    },
    {
      title: "Status",
      key: "status",
      render: (item, record, index) => (
        <Space size="middle">
          <Switch
            checked={item.status}
            onChange={() => onUpdateItem(record, {status : !item.status})}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <Space size="middle">
          {/* <EditOutlined
            style={{ fontSize: 22 }}
            onClick={() => onActionItem(item,record, index)}
          /> */}

          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => onUpdateItem(record, { is_deleted: true })}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 22, color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [newItem, addNewItem] = useState("");
  const onNameChange = (event) => {
    addNewItem(event.target.value);
  };

  const addItem = (data) => {
    console.log(data);

    if (data != "") addUnit({ name: data });

    addNewItem("");
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title={`Tags`}
        className="site-page-header"
        extra={[
          <Input.Search
          allowClear
          style={{ width: "400px" }}
          defaultValue=""
          placeholder="Search anything.."
          onChange={(e) => {
            console.log(e.target.value);
            setFilterData({ ...filterData, query: e.target.value.toLowerCase() });
          }}
        />,

          <Button
            key="1"
            type="primary"
            onClick={() => {
              form.resetFields();
              updateDrawer(true);
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <Table columns={columns} 
      dataSource={data?.filter(
        (item) =>
          (item?.title?.toLowerCase())?.includes(filterData.query)
      )} 
      size="small" />
      <Drawer
        title="Create a new item"
        width={600}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => updateDrawer(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          values={formValues}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="isFormEdit" hidden={true}></Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="uniId" hidden={true}></Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: false,
                    message: "Please input tile!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="detail"
                label="Detail"
                rules={[
                  {
                    required: false,
                    message: "Please input detail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
        
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  data: state.cms?.[CMS_TYPE],
});

const mapDispatchToProps = (dispatch) => ({

  updateCMS: (data, uniId, type) => dispatch(putCMS(data, uniId,type)),
  addCMS: (data, type) => dispatch(createCMS(data, type)),
  getCMS: (data, type) => dispatch(fetchCMS(data, CMS_TYPE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTags);

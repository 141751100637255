import { combineReducers } from 'redux'
import common from './common';
import users from './user';
import notification from './notification';
import auth from './auth';
import levent from './levent';
import cms from './cms';
import products from './product';
export default combineReducers({
  common,
  users,
  notification,
  auth,
  levent,
  cms,
  products
})